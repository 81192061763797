import React from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse the query string
function useUrl() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default useUrl;
